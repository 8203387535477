.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;

  background: hsla(208, 100%, 97%, 1);
  background: linear-gradient(90deg, hsla(208, 100%, 97%, 1) 0%, hsla(219, 79%, 66%, 1) 100%);
  background: -moz-linear-gradient(90deg, hsla(208, 100%, 97%, 1) 0%, hsla(219, 79%, 66%, 1) 100%);
  background: -webkit-linear-gradient(90deg, hsla(208, 100%, 97%, 1) 0%, hsla(219, 79%, 66%, 1) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#F0F8FF", endColorstr="#6495ED", GradientType=1);
}

.b {
  border: 1px solid red;
}

.wrapper {
  min-height: calc(100vh - 70px);
}

.header {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .05);
  width: 100%;
  height: 70px;
}

/* create a blue button with good border radius, add hover effect and make all button look same  */
button {
  background-color: #007bff;
  border-radius: 0.25rem;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  cursor: pointer;
  transition-duration: 0.4s;
  width: 200px;
  margin: 20px;
}

/*  update input element to match .button theme */

input {
  border: 2px solid #007bff;
  border-radius: 0.25rem;
  /* color: white; */
  padding: 0.2rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  cursor: pointer;
  transition-duration: 0.4s;
  width: 300px;
}

.red {
  background-color: #dc3545;
}

.red:hover {
  background-color: #c82333;
}

.green {
  background-color: #28a745;
}

.green:hover {
  background-color: #218838;
}

.gray {
  background-color: #6c757d;
}

.gray:hover {
  background-color: #5a6268;
}

.button_container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.upload_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}